import React from 'react'
import { Box, ListItemText, Typography } from '@material-ui/core'
import { css } from '@emotion/react'

const greenColorCss = css`
    font-weight: bold;
    color: #4caf50;
    font-size: 18px;
`
const redColorCss = css`
    font-weight: bold;
    color: #f44336;
    font-size: 18px;
`

const PendingRenovacion = (props) => {

    const numberFormat = (cantidad) => {        
        return new Intl.NumberFormat("es-MX", {style: "currency", currency: "MXN"}).format(cantidad)        
    }    

    return (
        <Box style={{marginTop: "-40px"}}>
            <ListItemText                                        
                primary={
                <Typography component="div" align="center">
                    <br />
                    <Box fontWeight='fontWeightRegular' >                        
                        Tenemos una renovación para ti por <span css={greenColorCss}>{numberFormat(props.credito)}</span>, 
                        sólo necesitamos saldar tu deuda anterior por 
                        <span css={redColorCss}> {numberFormat(props.pendiente)}</span> para conseguirlo.
                        <br />
                        <br />
                        ¡Salda tu deuda hoy mismo y recarga está página para
                        recibir tu renovación de crédito!
                    </Box>
                </Typography>
                }                                                                                                             
            />
        </Box>
    )
}

export default PendingRenovacion