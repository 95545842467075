import { FormControl, FormHelperText, InputLabel, makeStyles, Select } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(.5),
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    }
}));

const SelectAccountOpening = ({ disabled, messageError, years, months, onChange, openingMonth, openingYear }) => {
    const classes = useStyles();
    
    return (
        <div style={{ marginTop: '10px' }}>
            <FormControl sx={{ m: 3, minWidth: 120 }} variant="outlined" className={classes.formControl}>
                <InputLabel htmlFor="outlined-month-native-simple">Mes</InputLabel>
                <Select
                    style={{ fontSize: '12px' }}
                    disabled={disabled}
                    autoWidth={true}
                    native
                    value={openingMonth}
                    onChange={(e) => onChange('openingMonth', { value: e.target.value })}
                    label="Anio"
                    inputProps={{
                        name: 'month',
                        id: 'outlined-month-native-simple',
                    }}
                >
                    <option key="noneMonth" aria-label="None" value="" />
                    {
                        months.map((row, index) => {
                            return <option key={index + 3} value={row.number}>{row.name}</option>
                        })
                    }
                </Select>
            </FormControl>
            <FormControl sx={{ m: 3, minWidth: 120 }} variant="outlined" className={classes.formControl}>
                <InputLabel htmlFor="outlined-age-native-simple">Año</InputLabel>
                { years.length > 0 && <Select
                    style={{ fontSize: '12px' }}
                    disabled={disabled}
                    autoWidth={true}
                    native
                    value={openingYear}
                    onChange={(e) => onChange('openingYear', { value: e.target.value })}
                    label="Anio"
                    inputProps={{
                        name: 'anio',
                        id: 'outlined-age-native-simple',
                    }}
                >
                    <option key="noneyear" aria-label="None" value="" />
                    {
                        years.map((row, index) => {
                            return <option key={index + 3} value={row}>{row}</option>
                        })
                    }
                </Select>
            }
            </FormControl>
            <FormControl error={messageError} style={{ paddingLeft: 10 }}>
                <FormHelperText>Es necesario que ingreses la fecha de apertura de tu cuenta</FormHelperText>
            </FormControl>
        </div>
    )
}

export default SelectAccountOpening