import React from "react"

import SEO from "../components/seo"
// import Layout from '../layouts/findep'
import LayoutsComponent from '../layouts/LayoutsComponent';
import PreOferta from '../components/pages/PreOferta'
import GlobalFooterProvider from "../context/GlobalStateFooterContext";

const TuOferta = ({ pageContext, location }) => (
  <GlobalFooterProvider>
    <LayoutsComponent
      broker={pageContext.broker}
      layout={pageContext.layout}
      pageContext={pageContext}
      location={location}
      companyName={pageContext.company}
      currentStep={pageContext.step}
      render={() => (
        <>
          <SEO title={pageContext.title} />
          <PreOferta pageContext={pageContext} />
        </>
      )}
/>
  </GlobalFooterProvider>
)

export default TuOferta