import React from 'react'
import { ModalInfo } from '@findep/mf-landings-core'
import InfoIcon from '@material-ui/icons/Info';
import ActiveRenovacion from './ActiveRenovacion'
import PendingRenovacion from './PendingRenovacion'

const RenovacionModal = (props) => {

    const pendingRenovacion = props.renovacion.pendiente ? true : false
    const body = pendingRenovacion ? <PendingRenovacion pendiente={props.renovacion.pendiente} credito={props.renovacion.credito} /> : <ActiveRenovacion oferta={props.oferta} ofertas={props.ofertas} renovacion={props.renovacion} />
    const title = pendingRenovacion ? '¡Salda tu deuda!' : 'Tu renovación'
    const backgroundTitle = pendingRenovacion ? 'primary' : 'success'


    return (
        <ModalInfo 
                id='renovacion-modal'                    
                onClose={props.handleCloseModalRenovacion} 
                autoClose={!props.activeRenovacion} 
                icon={InfoIcon}                     
                rootCss="
                    @media(min-width: 768px){
                        width: 50%!important;
                        height: auto!import;                            
                    }
                    @media(min-width: 1024px){
                        width: 40%!important; 
                        height: auto!import;                           
                    }
                    @media(min-width: 1440px){
                        width: 35%!important;   
                        height: auto!import;                     
                    }"
                title={title}
                color={backgroundTitle}                                         
                open={props.activeRenovacion}
                body={ body } 
                btnLeft={!pendingRenovacion && "NO ACEPTO"} 
                btnRight={!pendingRenovacion && "ACEPTO" }
                onChange={(data) => props.handleNextStep(data)}                     
            />    
    )
}
export default RenovacionModal
