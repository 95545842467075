import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import Blue from '../../assets/svg/scoreBuro_B.svg'
import Green from '../../assets/svg/scoreBuro_G.svg'
import Orange from '../../assets/svg/scoreBuro_O.svg'
import Red from '../../assets/svg/scoreBuro_R.svg'
import Yellow from '../../assets/svg/scoreBuro_Y.svg'

function Arcoiris({color, bcScore}) {
    const CurrentColor = () => {
        switch (color[0]) {
            case 'RED':
                return Red;

            case 'ORANGE':
                return Orange;

            case 'YELLOW':
                return Yellow;

            case 'BLUE':
                return Blue;
                        
            default:
                return Green;
        }
    }

    const ScoreContainer = styled.div`
        background-image: url(${CurrentColor()});
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        margin-left: auto;
        margin-right: auto;
        height: 120px;
        width: 250px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-content: center;
        text-align: center;
        & span {
            font-size: 3em;
            font-weight: bold;
        }
`

    return (
        <ScoreContainer item style={{align: "center"}}>
            <span id="evaluacion-bcScore">{bcScore}</span>
        </ScoreContainer>        
    )
}

Arcoiris.propTypes = {
    color: PropTypes.oneOf(['RED', 'ORANGE', 'YELLOW', 'BLUE', 'GREEN']),
    bcScore: PropTypes.number
}

export default Arcoiris

